<template>
    <div >
        <div v-if="LocalCashup"> 
            <v-menu 
                v-model="menuSelect" 
                :close-on-content-click="false" 
                offset-y  offset-overflow
                rounded="xl" max-width="500" nudge-width="300"
                >
                <template v-slot:activator="{ on }"> 
                    <v-card   v-on="on" class="py-1"  color="transparent"  outlined
                         min-height="20" rounded="lg" flat >
                        <v-layout fill-height align-center class="ma-0 px-2 pb-1 pt-1 mbs-one-line  "> 
                            <v-card v-if="false" rounded="" outlined class="ma-1 pa-1"> 
                                <v-badge :value="showOffline"
                                    bordered
                                    :class="MBS.data.animate.zoom.in"
                                    :color="onlineIndicator" dot
                                    overlap
                                > 
                                    <v-icon :small="vs.smAndDown" color="primary">mdi-cash-register</v-icon>
                                </v-badge>
                            </v-card>
                            <v-icon v-else :small="vs.smAndDown" color="primary" class="ma-1">mdi-cash-register</v-icon>
                            <div class="font-weight-bold not-f3 grey--text text--darken-1">
                                {{LiveLocalCashup?LiveLocalCashup:'.'}}
                                </div> 
                            <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                            </v-layout>  
                    </v-card> 
                </template> 
    
                <v-card  v-if="ud"  
                    color="lighten-1" class="">  
    
                    <!-- //HEADER -->
                    <v-card-title class="secondary white--text" dark >
                        <v-list-item  dark> 
                            <v-list-item-content>
                                <v-list-item-title>Register Opened</v-list-item-title>
                                <v-list-item-subtitle>{{selectedCompany?selectedCompany.company_name:'..'}}</v-list-item-subtitle>  
                            </v-list-item-content>
                            <v-list-item-action><v-btn icon="" @click="menuSelect=false"><v-icon>close</v-icon></v-btn></v-list-item-action>
                        </v-list-item>
                    </v-card-title> 
                    <!-- //BODY --> 
                    <v-list ref="body_height" dense :max-height="vsb.scroll?vsh-175:''" :class="vsb.scroll?'mbs-scroll-y':''">
                        <v-list-item >
                            <v-list-item-action><v-icon >mdi-cash-register</v-icon></v-list-item-action>
                            <v-list-item-content>
                                <div>Outlet:</div>
                                <div>Till:</div> 
                                <div>Teller:</div> 
                            </v-list-item-content>
                            <v-list-item-action>
                                <div>{{Joined_LocalCashup?Joined_LocalCashup.outlet_name:'..'}}</div>
                                <div>{{Joined_LocalCashup?Joined_LocalCashup.till_name:'..'}}</div> 
                                <div>{{Joined_LocalCashup?Joined_LocalCashup.teller_name:'..'}}</div> 
                            </v-list-item-action>
                        </v-list-item>  
                        
                        <v-list-item  >
                            <v-list-item-action><v-icon>mdi-timer-outline </v-icon></v-list-item-action>
                            <v-list-item-content>
                                <div>Date:</div> 
                                <div>Duration:</div> 
                                <div>Opening Cash:</div>
                            </v-list-item-content>
                            <v-list-item-action>
                                <div>{{Joined_LocalCashup.local_created_at_}}</div> 
                                <div class="font-weight-bold">{{LiveLocalCashup?LiveLocalCashup:'.'}}</div> 
                                <div>{{MBS.actions.money(Joined_LocalCashup.opening_cash)}}</div>  
                            </v-list-item-action>
                        </v-list-item>  
                        
                        <v-list-item  >
                            <v-list-item-action><v-icon>mdi-cart</v-icon></v-list-item-action>
                            <v-list-item-content>
                                <div v-for="(tier,index) in OrdersPricingTiers">
                                    {{tier.name}}: <small>{{tier.quantity}} item(s)</small>
                                </div> 
                            </v-list-item-content>
                            <v-list-item-action>
                                <div v-for="(tier,index) in OrdersPricingTiers">
                                    {{MBS.actions.money(tier.sum_amount)}} 
                                </div>  
                            </v-list-item-action>
                        </v-list-item> 
    
                        
                        <!-- //CARDS -->
                        <v-list-item v-if="false" >
                            <v-list-item-action><v-icon>{{mdiAccountCreditCard}}</v-icon></v-list-item-action>
                            <v-list-item-content> 
                                <div>Cash Card Load: {{MBS.actions.SIZE(CashCardMoneyLoadOrders)}} Transaction (s)</div> 
                                <div>Other Card Load: {{MBS.actions.SIZE(OtherOptionCardMoneyLoadOrders)}} Transaction (s)</div> 
                                <div>Total Card Load: {{MBS.actions.SIZE(CardMoneyLoadOrders)}} Transaction (s)</div> 
                            </v-list-item-content>
                            <v-list-item-action>
                                <div>{{MBS.actions.money(TOTAL_PAID_AMOUNT(CashCardMoneyLoadOrders))}}</div>
                                <div>{{MBS.actions.money(TOTAL_PAID_AMOUNT(OtherOptionCardMoneyLoadOrders))}}</div>    
                                <div>{{MBS.actions.money(TOTAL_PAID_AMOUNT(CardMoneyLoadOrders))}}</div>    
                            </v-list-item-action>
                        </v-list-item> 
                        
                        <v-list-item  >
                            <v-list-item-action><v-icon>mdi-cash-clock</v-icon></v-list-item-action>
                            <v-list-item-content>  
                                <div>Paid Invoices (Cash): <small>{{MBS.actions.SIZE(CashInvoicePayments)}} Invoice(s)</small></div>  
                                <div>Paid Invoices (Other): <small>{{MBS.actions.SIZE(OtherInvoicePayments)}} Invoice(s)</small></div>  
                            </v-list-item-content>
                            <v-list-item-action> 
                                <div>{{MBS.actions.money(TOTAL_AMOUNT(CashInvoicePayments))}}</div>   
                                <div>{{MBS.actions.money(TOTAL_AMOUNT(OtherInvoicePayments))}}</div>   
                            </v-list-item-action>
                        </v-list-item>  
                        
                       
                        
                        <v-list-item  >
                            <v-list-item-action><v-icon>shopping_basket</v-icon></v-list-item-action>
                            <v-list-item-content> 
                                <div>Cash Sales:</div> 
                                <div>Other Sales (Banks):</div>  
                                <div>Credit Sales (On Account):</div> 
                            </v-list-item-content>
                            <v-list-item-action> 
                                <div>{{MBS.actions.money(TOTAL_SOLD(CashOrdersItems)+InputCashPayments)}}</div>
                                <div>{{MBS.actions.money(TOTAL_SOLD(OtherOptionPaymentsOrdersItems)+InputOtherPayments)}}</div>  
                                <div>{{MBS.actions.money(TOTAL_SOLD(OnAccountOrdersItems))}}</div>  
                            </v-list-item-action>
                        </v-list-item>   
                        <v-list-item  class="font-weight-bold grey--text text--darken-2"> 
                            <v-list-item-content> 
                                <div class="not-f" >TOTAL SALES:</div>  
                                <div class="not-f">CURRENT CASH:</div> 
                            </v-list-item-content>
                            <v-list-item-action> 
                                <div>{{MBS.actions.money(TOTAL_SOLD(OrdersItems))}}</div>   
                                <div class="not-f">{{MBS.actions.money(
                                    TOTAL_SOLD(CashOrdersItems) 
                                    +InputCashPayments
                                    +TOTAL_AMOUNT(CashInvoicePayments)
                                    +toNumber(Joined_LocalCashup.opening_cash)
                                    +TOTAL_PAID_AMOUNT(CashCardMoneyLoadOrders)
                                )}}</div> 
                            </v-list-item-action>
                        </v-list-item>   
                    </v-list>  
                    <v-divider></v-divider> 
                    <!-- //FOOTER  -->
                    <v-card-actions class="pa-0">
                        <v-btn @click="ON_CLOSE_REGISTER()" text height="50" width="100%" color="secondary">Close Register</v-btn>
                    </v-card-actions>
                    <!-- //FOOTER  -->
                    <!-- <v-card-actions class="pa-0">
                        <v-btn @click="ON_TEST()" text height="50" width="100%" color="secondary">Test</v-btn>
                    </v-card-actions> -->
                </v-card>  
            </v-menu>   
        </div>

        <div>
            <!-- CONFIRM PASSWORD BEFORE CLOSING -->
            <v-dialog
                v-model="dialog_confirm_before_closing"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="700px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="dialog_confirm_before_closing" > 
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text"> 
                            <span >CLOSE REGISTER</span> 
                        </div> 
                        <v-spacer></v-spacer> 
                        <v-btn class="ml-9" @click="dialog_confirm_before_closing=false" icon dark><v-icon >close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <br> 
                        <v-layout   justify-center> 
                            <v-icon size="150">$printer-pos-pause</v-icon>
                        </v-layout>
                        <form @submit.prevent="CONFIRM_BEFORE_CLOSING(input.password)"> 
                            <v-layout row wrap class="mx-0 my-6"  > 
                                <v-flex xs12 >
                                    <div class="mb-2 ml-">Enter your password to confirm closing this register</div>
                                    <v-text-field required prepend-inner-icon="lock"
                                        v-model="input.password"
                                        filled outlined
                                        label="" autofocus clearable
                                        type="password" autocomplete="one-time-code"  
                                        hint="0.00" class="mr-1"  />  
                                </v-flex> 
                                <v-flex xs12   class="my-3">
                                    <v-layout row wrap class="ma-0 pr-1">
                                        
                                        <v-btn   @click="CONFIRM_FORCE_CLOSING_REGISTER()"
                                            :loading="inputLoading" class="mr-2"
                                            color="error" height="50"  >
                                            <div class="not-f5">FORCE CLOSING</div>
                                        </v-btn> 
                                        <v-flex  >
                                            <v-btn   
                                                type="submit"   
                                                :loading="inputLoading"
                                                color="secondary" height="50" width="100%" >
                                                <div class="not-f5">
                                                    <!-- <v-icon class="pr-1 pb-1">mdi-paperclip-plus</v-icon> -->
                                                    CONFIRM PASSWORD
                                                </div>
                                            </v-btn>      
                                        </v-flex>    
                                    </v-layout>
                                </v-flex> 
                            </v-layout>  
                        </form>
                    </v-card-text>  
                </v-card> 
            </v-dialog> 

            <!-- OPEN DRAWER BEFORE CLOSE REGISTER -->
            <v-dialog
                v-model="dialog_drawer_close_register"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="lg">
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">CLOSE THIS REGISTER</div> 
                        <v-spacer></v-spacer>
                        <!-- <div class="font-weight-bold b2--text not-f7">Expect: mwk {{MBS.actions.money(input.expected_cash)}} </div> -->
                        <v-btn dark icon class="ml-5" @click="dialog_drawer_close_register=false" ><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <br> 
                        <form @submit.prevent="CLOSE_CASHUP()">
                            <div class="ma-5" v-if="dialog_drawer_close_register">
                                <mbs-input-fields 
                                    v-model="input"
                                    :disabled_fields="DisabledCashupFields"
                                    :field_items="DATA.FIELDS.close_cashups"
                                    :select_items="SelectInputFieldsItems"  
                                    />    
                                <v-layout class="ma-0 mt-5">
                                    <v-spacer></v-spacer>
                                    <!-- <v-btn text large color="primary" @click="dialog_drawer_close_register = false">Not now</v-btn> -->
                                    <v-btn :loading="inputLoading" type="" large color="primary">Close Register</v-btn>
                                </v-layout>
                            </div> 
                        </form>
                    </v-card-text> 
                </v-card> 
            </v-dialog> 

             <!-- DRAWER BEFORE CLOSING -->
            <v-dialog
                v-model="dialog_open_drawer_before_closing_register"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="700px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="dialog_open_drawer_before_closing_register" > 
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text"> 
                            <span >CASHUP BEFORE CLOSING</span> 
                        </div> 
                        <v-spacer></v-spacer> 
                        <v-btn class="ml-9" @click="dialog_open_drawer_before_closing_register=false" icon dark><v-icon >close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <br> 
                        <v-layout   justify-center> 
                            <v-btn :loading="inputLoading" icon class="my-9" @click="OPEN_DRAWER()">
                                <v-icon size="150">mdi-archive-arrow-down-outline</v-icon> 
                            </v-btn>
                        </v-layout>
                        <form @submit.prevent="CONFIRM_CLOSING_REGISTER()"> 
                            <v-layout row wrap class="mx-0 my-6"  > 
                                <v-flex xs12 >
                                    <div class="mb-2 ml-3">
                                        You must count cash before closing Register. This helps monitor the flow of cash. 
                                    </div> 
                                </v-flex> 
                                <v-layout >
                                    <v-card outlined >
                                        <v-simple-table  fixed-header   >
                                            <template v-slot:default>
                                                <thead class="mbs-one-line" > 
                                                    <tr >
                                                        <th class="text-uppercase primary--text b2">
                                                        </th>
                                                        <th class="text-uppercase black--text center-text b2 " > 
                                                            Notes/Coins (CASH) 
                                                        </th>
                                                        <th class="text-uppercase text-center black--text b2">
                                                            QTY (No.)
                                                        </th>
                                                        <th class="text-uppercase text-center black--text b2 ">
                                                            AMOUNT (MWK)
                                                        </th>  
                                                    </tr>  
                                                    <br>
                                                </thead>
                                                <tbody class="mt-5">
                                                    <tr class="mt-5"
                                                        v-for="(item,index) in currency_list"
                                                        :key="index" >
                                                        
                                                        <td> 
                                                            
                                                        </td>
                                                        <td class="text-right"> 
                                                            {{item.name}}
                                                        </td>
                                                        <td class="text-center pa-0"> 
                                                            <v-text-field   full-width
                                                                :required="false"
                                                                prepend-inner-icon="mdi-pound"
                                                                v-model="item.quantity"
                                                                filled  outlined  v-mask="currencyMask"
                                                                label=""  
                                                                type=""
                                                                hint=""   />  
                                                        </td>
                                                        <td class="pa">
                                                            <v-text-field disabled prepend-inner-icon="mdi-cash-multiple" 
                                                                filled outlined  :value="MBS.actions.money(toNumber(item.value )* toNumber(item.quantity))"
                                                                label=""   
                                                                class="mr-1"  />   
                                                        </td>  
                                                    </tr>
                                                    <br>
                                                    <tr > 
                                                        <td class="b1 darken-1">  
                                                        </td>
                                                        <td class="text-right b1 darken-1"> 
                                                            
                                                        </td>
                                                        <td class="text-center  font-weight-bold b1 darken-1">
                                                            TOTAL AMOUNT
                                                        </td>
                                                        <td class="text-center font-weight-bold b1 darken-2">
                                                            {{ MBS.actions.money(TotalCurrency) }}
                                                        </td>  
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table> 
                                    </v-card> 
                                </v-layout>
                                <v-flex xs12   class="my-3">
                                    <v-layout row wrap class="ma-0 pr-1">
                                        
                                        <v-btn   @click="dialog_open_drawer_before_closing_register=false"
                                            :loading="inputLoading" class="mr-2"
                                            color="primary" height="50"  >
                                            <div class="not-f5">CANCEL</div>
                                        </v-btn> 
                                        <v-flex  >
                                            <v-btn   
                                                type="submit"   
                                                :loading="inputLoading"
                                                color="secondary" height="50" width="100%" >
                                                <div class="not-f5">
                                                    <v-icon size="35" class="pr-1 pb-1">$printer-pos-pause</v-icon>
                                                    CLOSE REGISTER
                                                </div>
                                            </v-btn>      
                                        </v-flex>    
                                    </v-layout>
                                </v-flex> 
                            </v-layout>  
                        </form>
                    </v-card-text>  
                </v-card> 
            </v-dialog> 

            <!-- //print -->
            <div v-if="auto_print.printing"> 
                <mbs-auto-print 
                    @finish_print="FINISH_PRINT"
                    :show="true"  
                    :auto_print="auto_print.auto"
                    :auto_printer_name="auto_print.auto_printer_name"
                    :com_printer="auto_print.com_printer"  
                    :data="auto_print.print_data"
                    :type="auto_print.print_type"
                    :title="auto_print.print_title"
                    :printing_action="auto_print.printing_action"
                    :display_option="auto_print.display_option" 
                    />  
            </div>  
        </div>
    </div>
</template> 
<script>
let PAGE_NAME = 'LIVE-TILL'
import DATA from "../../plugins/DATA"
import DB from "../../plugins/DB"
import * as mbs_dayshift from '../../plugins/mbs_dayshift'
import {mapState,mapGetters } from "vuex"
import { mdiAccountCreditCard } from '@mdi/js';
import { toNumber } from '../../plugins/formatNumber'
export default { 
    data(){
        return{  
            body_height:0,
            dialog:false, 
            showOffline:true,
            input:{}, 
            menuSelect:false,
            dialog_drawer_close_register:false, 
            dialog_open_drawer_before_closing_register:false, 
            dialog_confirm_before_closing:false, 
            DATA:DATA, 
            mdiAccountCreditCard:mdiAccountCreditCard, 
            currency_list:[
                {value:1,name:"1.00",icon:"mdi-cash"},
                {value:5,name:"5.00",icon:"mdi-cash"},
                {value:10,name:"10.00",icon:"mdi-cash"},
                {value:20,name:"20.00",icon:"mdi-cash"},
                {value:50,name:"50.00",icon:"mdi-cash"},
                {value:100,name:"100.00",icon:"mdi-cash"},
                {value:200,name:"200.00",icon:"mdi-cash"},
                {value:500,name:"500.00",icon:"mdi-cash"},
                {value:1000,name:"1,000.00",icon:"mdi-cash"},
                {value:2000,name:"2,000.00",icon:"mdi-cash"},
                {value:5000,name:"5,000.00",icon:"mdi-cash"},
            ], 

            auto_print:{
                auto:false,
                printing:false, 
                print_data:null,
                print_type:null,
                com_printer:false, 
                print_title:null, 
                auto_printer_name:null,
                printing_action:null, 
                display_option:'',  
            },
            
        } 
    }, 
    created() {
        try {
            this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            this.MBS.events.$on('ON_CLOSE_REGISTER', this.ON_CLOSE_REGISTER);  
            // console.log('action set--------------i-');
            // this.MBS.events.$on('ITEM_ACTION', this.ITEM_ACTION);  
            this.SHOW_OFFLINE()
        } catch (error) {
            this.MBS.actions.error({
                error:error,
                from:'created',
                page:PAGE_NAME, 
            }) 
        }
    },
    mounted(){ 
        this.input={}
        this.inputLoading=false   
        this.COMPANY_QUICK_ACTIONS(this.CompanyQuickActions)
        this.CHECK_TILL(this.Joined_LocalCashup)
        this.FI_INITIATE()
    },
    computed:{
        route(){
            return this.$route
        },
        vs(){

            return this.$vuetify.breakpoint
        }, 
        vsh(){
            return this.vs.height
        },  
        vsb(){ 
            let body_height = this.body_height 
            let height = this.vs.height 
            body_height = this.$refs.body_height?this.$refs.body_height.$el.clientHeight:body_height;
            let diff = height-body_height
            return {
                height:height,
                body_height:body_height,
                diff:diff,
                scroll:body_height?diff>180?false:true:false,
            }
        },
        online(){   
            return this.$store.getters.getOnlineStatus
        },
        onlineLAN(){   
            return this.$store.getters.getOnlineLanStatus
        },
        onlineIndicator(){
            const online = this.online
            const onlineLAN = this.onlineLAN
            let light = "green"
            if (!online) {
                light = "red"
            } else if (!onlineLAN) {
                light = "orange"
            } else {
                
            }
            return light
        },
        ...mapState({
            loading:state=>state.load.loading,
            processes:state=>state.load.processes,
            responses:state=>state.load.responses,
        }),
        ...mapState({
            us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
            ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
            K_CONNECTOR: state=> state.items['K_CONNECTOR'],
        }),
        ...mapState({
            CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
            CompanyQuickActions: state=> state.items[(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values).toUpperCase()], 
            Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
            JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
            ItemOrders: state=> state.items[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
            JoinedItemOrders: state=> state.join[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
            LocalItemOrders: state=> state.items[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
            JoinedLocalItemOrders: state=> state.join[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
            
            
            Payments: state=> state.items[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],
            JoinedPayments: state=> state.join[(DATA.ITEMS.PAYMENTS.values).toUpperCase()], 
            PaidInvoices: state=> state.items[(DATA.ITEMS.PAID_INVOICES.values).toUpperCase()],
            JoinedPaidInvoices: state=> state.join[(DATA.ITEMS.PAID_INVOICES.values).toUpperCase()], 
            SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
            JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],  
            LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
            JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
            JoinedLocalCashups: state=> state.join[(DATA.ITEMS.LOCAL_CASHUPS.values).toUpperCase()],
            Tills: state=> state.items[(DATA.ITEMS.TILLS.values).toUpperCase()],
            JoinedTills: state=> state.join[(DATA.ITEMS.TILLS.values).toUpperCase()],  
            LocalCashups: state=> state.locals[(DATA.ITEMS.LOCAL_CASHUPS.values).toUpperCase()],

            PricingTiers: state=> state.items[(DATA.ITEMS.PRICING_TIERS.values).toUpperCase()], 
            JoinedPricingTiers: state=> state.join[(DATA.ITEMS.PRICING_TIERS.values).toUpperCase()], 
            DefaultPricingTiers: state=> state.app['DEFAULT_PRICING_TIERS'], 
        
            DaySales: state=> state.items[(DATA.ITEMS.DAY_SALES.values).toUpperCase()],
            JoinedDaySales: state=> state.join[(DATA.ITEMS.DAY_SALES.values).toUpperCase()],  
            DayOrders: state=> state.items[(DATA.ITEMS.DAY_ORDERS.values).toUpperCase()],
            JoinedDayOrders: state=> state.join[(DATA.ITEMS.DAY_ORDERS.values).toUpperCase()], 
                
        }),
        DaySalesData(){ 
            let cashup = this.cashup
            let cashup_key = cashup?cashup.key:null
            let DaySales = this.DaySales
            let JoinedDaySales = this.JoinedDaySales
            let items = JoinedDaySales?JoinedDaySales:DaySales  
            if(!items){return null}
            let list = [...items] 
            let filtered = list.filter(item=>{
                return item.cashup_key+"" == cashup_key+""
            }) 
            return cashup_key?filtered:list
        }, 
        DayOrdersData(){ 
            let cashup = this.cashup
            let cashup_key = cashup?cashup.key:null
            let DayOrders = this.DayOrders
            let JoinedDayOrders = this.JoinedDayOrders
            let items = JoinedDayOrders?JoinedDayOrders:DayOrders  
            if(!items){return null}
            let list = [...items] 
            let filtered = list.filter(item=>{
                return item.cashup_key+"" == cashup_key+""
            }) 
            return cashup_key?filtered:list
        }, 


        PricingTiersData(){
            return this.JoinedPricingTiers?this.JoinedPricingTiers:this.PricingTiers
        }, 
        AllPricingTiers(){
            let defaults = this.DefaultPricingTiers
            let tiers = this.PricingTiersData 
            let all = []
            if (defaults) {
                all.push(...defaults)
            }if (tiers) {
                tiers.forEach(tier => {
                    let index = all.findIndex(item=>item.key == tier.key)
                    if (index==-1) {
                        all.push(tier) 
                    } else {
                        all[index] = {
                            ...all[index],
                            ...tier
                        }
                    }
                });
            }
            return all
        }, 
        ItemOrdersData(){    
            return this.JoinedItemOrders?this.JoinedItemOrders:this.ItemOrders
        },  
        LocalItemOrdersData(){  
            return this.JoinedLocalItemOrders?this.JoinedLocalItemOrders:this.LocalItemOrders
        },
        LiveLocalItemOrder(){  
            const local = this.LocalItemOrdersData
            const live = this.ItemOrdersData   
            const find = key=>{
                if(!live){return null}
                return live.find(order=>order.key == key)
            } 
            let items = []
            if (this.MBS.actions.SIZE(live)>0) {
                items.push(...live)
            }
            if (this.MBS.actions.SIZE(local)>0) {
                local.forEach(element => {
                    // let uploaded_order = find(element.key)
                    const order_index =items.findIndex(order=>order.key == element.key)
                    if (order_index==-1) {
                        items.push(element)
                    }else{
                        items[order_index] = {
                            ...items[order_index],
                            ...element
                        }
                    }
                });
            }
            if(!items){return null}
            const list = [...items]
            list.sort(this.MBS.actions.dynamicSort("key",false))     
            return list 
        }, 
        ActiveOrders(){  
            const orders = this.LiveLocalItemOrder  
            if(!orders){return null}
            const filtered = orders.filter(order=>!order.deleted)    
            return filtered 
        }, 




        IsRetail(){
            const settings = this.CompanySettings
            if(!settings){return false}
            const only_retail_model = settings?settings.only_retail_model:false
            const only_wholesale_model = settings?settings.only_wholesale_model:false
            if (settings.both_selling_mode && !only_wholesale_model) {
                return true  
            } 
            return only_retail_model
        },
        IsWholesale(){
            const settings = this.CompanySettings
            if(!settings){return false}
            const only_retail_model = settings?settings.only_retail_model:false
            const only_wholesale_model = settings?settings.only_wholesale_model:false

            if (settings.both_selling_mode && !only_retail_model) {
                return true  
            } 
            return only_wholesale_model
        },
        DataInvoicePayments(){
            let Payments = this.Payments
            let JoinedPayments = this.JoinedPayments 
            return JoinedPayments?JoinedPayments:Payments
        },
        AllSaleOrdersData(){  
            const SaleOrders = this.SaleOrders
            const JoinedSaleOrders = this.JoinedSaleOrders
            let items = JoinedSaleOrders?JoinedSaleOrders:SaleOrders  
            // let dd = this.DaySalesData
            // items = dd?dd:items
            // console.log(dd,'ddddd..s..............');
            if(!items){return null} 
            let list = [...items]
            list.sort(this.MBS.actions.dynamicSort("local_created_at",true)) 
            return list
        }, 
        AllLocalSaleOrdersData(){  
            const LocalSaleOrders = this.LocalSaleOrders
            const JoinedLocalSaleOrders = this.JoinedLocalSaleOrders
            let items = JoinedLocalSaleOrders?JoinedLocalSaleOrders:LocalSaleOrders  
            if(!items){return null} 
            let list = [...items]
            list.sort(this.MBS.actions.dynamicSort("local_created_at",true)) 
            return list
        },
        AllLocalCashupsData(){  
            const LocalCashups = this.LocalCashups
            // const JoinedLocalCashups = this.JoinedLocalCashups
            // let items = JoinedLocalCashups?JoinedLocalCashups:LocalCashups  
            let items = LocalCashups  
            if(!items){return null} 
            let list = [...items]
            list.sort(this.MBS.actions.dynamicSort("local_created_at",true)) 
            return list
        },
        TillsData(){
            return this.JoinedTills?this.JoinedTills:this.Tills
        }, 
        PaidInvoicesData(){ 
            let items = this.JoinedPaidInvoices?this.JoinedPaidInvoices:this.PaidInvoices 
            if(!items){return null}
            let list = [...items]
            list.sort(this.MBS.actions.dynamicSort("local_created_at",true))  
            return list 
        }, 

        CurrentDayShift(){ 
            let shifts = this.DayShifts 
            let cashup = this.LocalCashup 
            let day_shift_key = cashup?.day_shift_key
            if(!shifts || !day_shift_key){return null}
            let filled = shifts.find(item=>{
                return item.key == day_shift_key
            })
            return filled
        },
        CurrentTill(){
            let tills = this.TillsData
            let cashup = this.LocalCashup 
            if(!tills || !cashup){return null}
            let filled = tills.find(item=>{
                return item.key == cashup.till_key
            })
            return filled
        },
        CurrentReceiptPrinter(){
            let till = this.CurrentTill 
            return till?till.printer:null
        },
        CashupSaleOrders(){ 
            let items = this.AllLocalSaleOrdersData
            // let dd = this.DaySalesData
            // items = dd?dd:items
            // console.log(dd,'ddddd..11s..............');
            // console.log(items,'items..11s.......d.......');
            let cashup_key = this.LocalCashup?this.LocalCashup.key:null
            if(!items){return null}
            let filtered_items = items.filter(item=>{
                // return true
                return item.cashup_key == cashup_key
            })   
            return filtered_items
        },   
        MyCompanies(){ 
            return this.$store.getters.getMyCompanies
        },
        JoinedMyCompanies(){ 
            const companies = this.MyCompanies 
            if(!companies){return null}
            let joined = this.MBS.actions.JOIN({
                name:DATA.ITEMS.COMPANIES.values,
                items:companies
            })  
            return joined
        },
        selectedCompany(){ 
            return this.$store.getters.getSelectedCompany
        },   
        LocalCashup(){   
            let cashups = this.LocalCashups
            let local_cashup = cashups?cashups.find(item=>{
                return !item.closed
            }):null 
            return local_cashup
        },
        Joined_LocalCashup(){   
            let localCashup = this.LocalCashup
            let joined = this.MBS.actions.JOIN_CASHUP(localCashup)  
            return joined
        },
        // SaleOrders(){ 
        //     let items = this.$store.getters.getSaleOrders 
        //     let cashup_key = this.LocalCashup?this.LocalCashup.key:null
        //     if(!items){return null}
        //     let filtered_items = items.filter(item=>{
        //         return !item.archive && item.cashup_key == cashup_key
        //     }) 
        //     let list = [...filtered_items]
        //     list.sort(this.MBS.actions.dynamicSort("local_created_at",true))  
        //     return list
        // }, 
        // Joined_SaleOrders(){   
        //     let orders = this.SaleOrders 
        //     let joined = this.MBS.actions.JOIN_SALE_ORDERS(orders)  
        //     return joined
        // },
         

        CashOrders(){   
            let orders = this.CashupSaleOrders
            if(!orders){return null}  
            let filtered_items = orders.filter(element => {
                return element.account_type == "cash"
            });
            return filtered_items
        },
        OnAccountOrders(){   
            let orders = this.CashupSaleOrders
            if(!orders){return null}  
            let filtered_items = orders.filter(element => {
                return element.account_type == "on_account"
            });
            return filtered_items
        },
        TotalDiscount(){   
            let orders = this.CashupSaleOrders
            if(!orders){return null} 
            let total = 0 
            orders.forEach(element => {
                let total_discount = toNumber(element.total_discount)
                total = total+total_discount
            });
            return total
        },
        CashTotalDiscount(){   
            let orders = this.CashupSaleOrders
            if(!orders){return null} 
            let total = 0 
            orders.forEach(element => {
                if (element.account_type == "cash") {
                    let total_discount = toNumber(element.total_discount)
                    total = total+total_discount 
                }
            });
            return total
        },
        OtherPaymentTotalDiscount(){   
            let orders = this.CashupSaleOrders
            if(!orders){return null} 
            let total = 0 
            orders.forEach(element => {
                if (element.account_type == "payment_option") {
                    let total_discount = toNumber(element.total_discount)
                    total = total+total_discount 
                }
            });
            return total
        },
        OnAccountTotalDiscount(){   
            let orders = this.CashupSaleOrders
            if(!orders){return null} 
            let total = 0 
            orders.forEach(element => {
                if (element.account_type == "on_account") {
                    let total_discount = toNumber(element.total_discount)
                    total = total+total_discount 
                }
            });
            return total
        },
        MultiplePayOrders(){
            const orders = this.CashupSaleOrders
            if(!orders){return null}
            const m_orders = orders.filter(order=>order.input_payments) 
            return m_orders
        },
        InputPayments(){
            const orders = this.MultiplePayOrders 
            let amounts = []
            if(orders){
                orders.forEach(order => {
                    const total_given_amount = this.toNumber(order.given_amount)
                    const total_paid_amount = this.toNumber(order.sum_amount?order.sum_amount:order.paid_amount)
                    const change_amount = total_given_amount-total_paid_amount
                    const discount = this.toNumber(order.total_discount)
                    const input_payments = order.input_payments
                    const account_type = order.account_type
                    if (input_payments&&account_type == 'multiple') {
                        for (let index = 0; index < input_payments.length; index++) {
                            const element = input_payments[index];
                            let given_amount = this.toNumber(element.given_amount)
                            if (element.key == 'cash') {
                                given_amount=given_amount-change_amount
                            }
                            let indexOf = amounts.findIndex(item=>item.key==element.key)
                            if (indexOf==-1) {
                                amounts.push({
                                    ...element,
                                    given_amount:given_amount
                                })
                            } else {
                                amounts[indexOf].given_amount = amounts[indexOf].given_amount+given_amount
                            }

                        } 
                    }
                });
            }  
            return amounts
        },
        InputCashPayments(){
            const payments = this.InputPayments
            if(!payments){return 0}
            let cash = payments.find(pay=>pay.key=="cash") 
            return cash?cash.given_amount:0
        },
        InputOtherPayments(){
            const payments = this.InputPayments
            if(!payments){return 0}
            let amount = 0
            payments.forEach(pay => {
                if (pay.key!="cash") {
                    amount = amount+pay.given_amount
                }
            }); 
            return amount
        },
        OrdersItems(){ 
            this.MultiplePayOrdersAmounts  
            let orders = this.CashupSaleOrders 
            if(!orders){return null} 
            let items = [] 
            orders.forEach(element => {
                let joined_items = element.joined_items
                if (!joined_items) {
                    joined_items = element.items
                }
                if (joined_items) {
                    let items_size = 0
                    joined_items.forEach(element => {
                        items_size = items_size+this.toNumber(element.quantity)
                    });
                    let total_discount = this.toNumber(element.total_discount)
                    let average_order_total_discount = total_discount?(total_discount/items_size):0 


                    joined_items.forEach(item => { 
                        items.push({
                            ...item,
                            account_type:element.account_type,
                            payment_option_key:element.payment_option_key,
                            average_order_total_discount:average_order_total_discount,
                            average_item_quantity : items_size
                        }) 
                    });
                    
                }
            });
            return items
        },
        CardMoneyLoadOrders(){   
            let orders = this.CashupSaleOrders
            if(!orders){return null}  
            let filtered = orders.filter(order=>{
                return order.selected_card_option=="load_money"
            }) 
            return filtered
        },
        CashCardMoneyLoadOrders(){   
            let orders = this.CardMoneyLoadOrders
            if(!orders){return null}  
            let filtered = orders.filter(order=>{
                return order.account_type=="cash"
            }) 
            return filtered
        },
        OtherOptionCardMoneyLoadOrders(){   
            let orders = this.CardMoneyLoadOrders
            if(!orders){return null}  
            let filtered = orders.filter(order=>{
                return order.account_type=="payment_option"
            }) 
            return filtered
        },

        OrdersPricingTiers(){   
            let items = this.OrdersItems  
            let pricing_tiers = this.AllPricingTiers
            if(!items){return null}  
            let tiers = [] 
            items.forEach(item => { 
                let pricing_tier_key = item.pricing_tier_key?item.pricing_tier_key:"retail_price"
                let sold_price = this.toNumber(item.sold_price)
                let quantity = this.toNumber(item.quantity)
                let discount = this.toNumber(item.discount)
                let sum_amount = (sold_price-discount)*quantity
                let index = tiers.findIndex(item=>item.key==pricing_tier_key)
                if (index==-1) {
                    let tier = pricing_tiers?pricing_tiers.find(item=>item.key == pricing_tier_key):null
                    let tier_details = {
                        pricing_tier_key:pricing_tier_key,
                        sold_price:sold_price,
                        quantity:quantity,
                        sum_amount:sum_amount,
                        size:1
                    }
                    if (tier) {
                        tier_details = {
                            ...tier,
                            ...tier_details,
                        }
                    }else{
                        console.log(tier,'tiers......ii.');
                        console.log(item,'item......ii.');
                    }
                    tiers.push(tier_details)
                } else {
                    tiers[index].sold_price = tiers[index].sold_price+sold_price
                    tiers[index].quantity = tiers[index].quantity+quantity
                    tiers[index].sum_amount = tiers[index].sum_amount+sum_amount
                    tiers[index].size++ 
                }
                
            }); 
             
            return tiers
        }, 


        CashOrdersItems(){   
            let orders = this.OrdersItems
            if(!orders){return null}  
            let filtered_items = orders.filter(element => {
                return element?.account_type == "cash" || element?.payment_option_key == "cash"
            });
             
            return filtered_items
        },
        OtherOptionPaymentsOrdersItems(){   
            let orders = this.OrdersItems
            if(!orders){return null}  
            let filtered_items = orders.filter(element => {
                return element.account_type == "payment_option" &&  element?.payment_option_key != "cash"
            });  
            return filtered_items
        },
        OnAccountOrdersItems(){   
            let orders = this.OrdersItems
            if(!orders){return null}  
            let filtered_items = orders.filter(element => {
                return element.account_type == "on_account"
            });
            return filtered_items
        }, 
        liveTime(){
            let time = this.MBS.date.live 
            return time
        },
        LiveLocalCashup(){  
            let localCashup = this.Joined_LocalCashup 
            let live = this.liveTime
            let moment = this.MBS.date.moment
            let local_created_at = localCashup?localCashup.local_created_at:null 
            if (local_created_at) {
                let diff_hours = moment(live).diff(local_created_at,'hours')
                let diff_minute = moment(live).diff(local_created_at,'minutes')
                let diff_seconds = moment(live).diff(local_created_at,'seconds')
                let sec = diff_seconds - (diff_minute*60)
                let min = diff_minute - (diff_hours*60)
                return ''+diff_hours+'h '+min+'m '+sec+'s'
            }  
            return moment(local_created_at).format('yyyy-MM-D .')
        },

        
        DayShifts(){ 
            let items = this.$store.getters.getDayShifts
            if(!items){return null}
            let list = [...items]
            list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 

            return list
        },  
        Joined_DayShifts(){ 
            let items = this.DayShifts
            if(!items){return null}
            let list = this.MBS.actions.JOIN({
                name:DATA.ITEMS.DAYS_SHIFTS.values,
                items:items
            }) 
            return list
        },  
        UNCLOSED_DayShifts(){ 
            let items = this.Joined_DayShifts
            if(!items){return null}
            let filtered_items = items.filter(item=>{
                return !item.archive && !item.closing_cash
            }) 
            return filtered_items
        }, 
        DayShiftsTills(){ 
            let items = this.UNCLOSED_DayShifts
            if(!items){return null}
            let tills = []
            items.forEach(element => {
                let this_tills = element.joined_tills
                if (this_tills) {
                    tills = [
                        ...tills,
                        ...this_tills
                    ]
                }
            }); 
            return tills
        },   
        SelectInputFieldsItems(){  
            let Tills = this.DayShiftsTills  
            return { 
                Tills:Tills,  
            }
        }, 

        // ThisInvoicePayments(){
        //     let payments = this.DataInvoicePayments
        //     let LocalCashup = this.LocalCashup
        //     let cashup_key = LocalCashup?LocalCashup.key:null
        //     let day_shift_key = LocalCashup?LocalCashup.day_shift_key:null
        //     if(!payments||!day_shift_key){return null}
        //     let filled_items = payments.filter(item=>{
        //         return item.cashup_key == cashup_key && item.day_shift_key == day_shift_key
        //     }) 
        //     return filled_items
        // },
        
        ThisInvoicePayments(){
            let payments = this.PaidInvoicesData 
            let LocalCashup = this.LocalCashup
            let cashup_key = LocalCashup?LocalCashup.key:null
            let day_shift_key = LocalCashup?LocalCashup.day_shift_key:null
            if(!payments||!day_shift_key){return null}
            let filled_items = payments.filter(item=>{
                return item.cashup_key == cashup_key && item.day_shift_key == day_shift_key
            }) 
            return filled_items
        },
        CashInvoicePayments(){
            let payments = this.ThisInvoicePayments   
            if(!payments){return null}
            let filled_items = payments.filter(item=>{
                return !item.payment_option_key||item.payment_option_key=="cash"
            }) 
            return filled_items
        },
        OtherInvoicePayments(){
            let payments = this.ThisInvoicePayments 
            if(!payments){return null}
            let filled_items = payments.filter(item=>{
                return item.payment_option_key!="cash"
            }) 
            return filled_items
        },
        
        DisabledCashupFields(){ 
            let settings = this.CompanySettings  
            let edit_closing_cash = settings?settings.edit_closing_cash:null
            if (!edit_closing_cash) {
                return ['closing_cash','expected_cash']
            }
            return null
        },  
        OtherSalesOptionPayments(){
            let items = this.OtherOptionPaymentsOrdersItems   
            let settings = this.CompanySettings   
            let vat_exclusive = settings?.items_are_vat_exclusive
            if(!items){return null}
            let options = []

            console.log(settings,'settings..................+++++');
            console.log(items,'items..................+++++');
            
            items.forEach(element => {
                let payment_option_key = element.payment_option_key
                let sold_price = this.toNumber(element.sold_price)
                let quantity = this.toNumber(element.quantity)
                let discount = this.toNumber(element.discount)+this.toNumber(element.average_order_total_discount)
                let price = (sold_price-discount) *quantity

                let tax_group_code = element?.tax_group_code 
                tax_group_code = tax_group_code?tax_group_code:"A" 
                let item_total_vat = 0
                let item_sum_amount = 0
                if (tax_group_code == "A" && vat_exclusive) {
                    item_total_vat = (sold_price*quantity)*16.5/100
                    item_sum_amount = price+item_total_vat
                } else {
                    item_sum_amount = price
                }


                let index = options.findIndex(option=>{
                    return option.payment_option_key  == payment_option_key
                }) 
                if (payment_option_key) {
                    if (index == -1) {
                        let payment_method = this.$store.getters.getPaymentMethodByKey(payment_option_key)
                        options.push({
                            payment_method:payment_method,
                            payment_method_name:payment_method?payment_method.name:"---",
                            payment_option_key:payment_option_key,
                            // amount:this.toNumber(element.sold_price)*this.toNumber(element.quantity),
                            amount:price,
                            total_vat : item_total_vat,
                            sum_amount : item_sum_amount,
                        })
                    } else {
                        options[index].amount = options[index].amount+price
                        options[index].total_vat = options[index].total_vat+item_total_vat
                        options[index].sum_amount = options[index].sum_amount+item_sum_amount
                                    
                    } 
                } 
            });   
            return options
        }, 
        OtherInvoiceOptionPayments(){
            let items = this.OtherInvoicePayments   
            if(!items){return null}
            let options = []
            items.forEach(element => {
                let payment_option_key = element.payment_option_key
                let index = options.findIndex(option=>{
                    return option.payment_option_key  == payment_option_key
                }) 
                if (payment_option_key) {
                    if (index == -1) {
                        let payment_method = this.$store.getters.getPaymentMethodByKey(payment_option_key)
                        options.push({
                            payment_method:payment_method,
                            payment_method_name:payment_method?payment_method.name:"---",
                            payment_option_key:payment_option_key,
                            amount:this.toNumber(element.amount) 
                        })
                    } else {
                        options[index].amount = options[index].amount+(this.toNumber(element.amount))
                    } 
                } 
            });  
            return options
        },
        TotalCurrency(){
            try { 
                let currencies = this.currency_list
                if(!currencies){0}
                let total = 0
                currencies.forEach(currency => {
                    total = total+(this.toNumber(currency.quantity)*this.toNumber(currency.value))
                });
                return total
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'TotalPayments',
                    page:PAGE_NAME, 
                }) 
                return 0
            }  
        }, 

    },
    methods:{ 
        async ON_TEST(){
            
            console.log(mbs_dayshift.running_cashups(this.CurrentDayShift),'ON_TEST.........');
            console.log(this.Joined_LocalCashup ,'this.Joined_LocalCashup .........');
            console.log(this.LocalCashup ,'this.LocalCashup .........');
            console.log(this.CurrentDayShift ,'this.CurrentDayShift .........');
            console.log(this.DaySales ,'this.DaySales ....++.....');

            console.log(await mbs_dayshift.sale_orders({
                local_orders:this.DaySales,
                shift:this.CurrentDayShift,
            }) ,'sales-------------++');
        },
        SHOW_OFFLINE(){ 
            this.showOffline = true 
            setTimeout(() => {
                this.showOffline = false 
                setTimeout(() => { 
                    this.SHOW_OFFLINE()
                }, 500);
            }, 3000);  
        },

        async FI_INITIATE(){ 
            try {
                // let day_shift = this.day_shift
                let day_shift_key = "1719803588940"
                if (true) {
                    this.downloading = true
                    let path_sales = this.MBS.actions.COMPANY_LINK(DATA.PATHS.days_shifts_sales+day_shift_key,true)
                    let path_orders = this.MBS.actions.COMPANY_LINK(DATA.PATHS.item_orders+day_shift_key,true)
                    console.log(path_sales,'path_sales....... .......***');
                    console.log(path_orders,'path_orders....... .......***');

                    let res = await this.$store.dispatch("fi_get_day_sales",{
                        path:path_sales
                    })  
                    console.log(res, '-----------fi');


                    // this.$store.dispatch("fi_get_day_orders",{
                    //     path:path_orders
                    // }).then(res=>{
                    //     this.downloading = false
                    //     console.log(res,'res....FI_INITIATE.....');
                    // }).catch(error=>{
                    //     this.downloading = false
                    //     console.log(error,'error.....FI_INITIATE.......');
                    // })
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'FI_INITIATE',
                    page:PAGE_NAME, 
                }) 
            }
        }, 
        FINISH_PRINT(action,payload){
            try {    
                let canceled = payload?payload.canceled:null
                if(!action){return}
                if (action.code == PAGE_NAME+"=CLOSE-CASHUP") {
                    // this.$store.dispatch("fi_uploadCashups")
                    // this.$store.dispatch("fi_uploadSales")
                    // this.SUBMIT_CLOSE_CASHUP(action.data) 
                    this.REFRESH_DATA()  
                }
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'FINISH_PRINT',
                    page:PAGE_NAME, 
                }) 
            } 
        },

        RESET_ALL_DATA(){ 
            try { 
                Object.assign(this.$data, this.$options.data())  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'RESET_ALL_DATA',
                    page:PAGE_NAME, 
                }) 
            }
        },
        TOTAL_AMOUNT(items){
            try {  
                let total_amount = 0 
                if (!items) {return 0}
                items.forEach(item => {
                    let amount = this.toNumber(item.amount) 
                    total_amount = total_amount + amount
                });  
                return total_amount
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'TOTAL_AMOUNT',
                    page:PAGE_NAME, 
                }) 
                return 0
            }  
        },  
        TOTAL_PAID_AMOUNT(items){
            try {  
                let total_amount = 0 
                if (!items) {return 0}
                items.forEach(item => {
                    let amount = this.toNumber(item.sum_amount?item.sum_amount:item.paid_amount) 
                    total_amount = total_amount + amount
                });  
                return total_amount
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'TOTAL_PAID_AMOUNT',
                    page:PAGE_NAME, 
                }) 
                return 0
            }  
        },  
        UPLOADED(items){
            try { 
                if(!items){return null}
                return items.filter(item=>{
                    return item.uploaded
                })  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'UPLOADED',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        UN_UPLOADED(items){
            try { 
                if(!items){return null}
                return items.filter(item=>{
                    return !item.uploaded
                })  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'UPLOADED',
                    page:PAGE_NAME, 
                }) 
            } 
        }, 
        TOTAL_SOLD(items){
            try {  
                let total = 0
                if (items) {
                    items.forEach(element => {
                        let sold_price = this.toNumber(element.sold_price)
                        let quantity = this.toNumber(element.quantity)
                        let discount = this.toNumber(element.discount)
                        let average_order_total_discount = this.toNumber(element.average_order_total_discount)
                        // discount = discount+average_order_total_discount
                        total = total+((sold_price-discount-average_order_total_discount)*quantity)
                    }); 
                }
                return total
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'TOTAL_SOLD',
                    page:PAGE_NAME, 
                })
            }  
        },  
        ON_CLOSE_REGISTER(payload){ 
            try {  
                console.log(payload,'ON_CLOSE_REGISTER----------');

                let settings = this.CompanySettings  
                let cashup_before_closing_till = settings?settings.cashup_before_closing_till:null
                let till_require_no_password = settings?settings.till_require_no_password:null

                let TotalPayments = this.TOTAL_AMOUNT(this.CashInvoicePayments)
                let cashup = this.Joined_LocalCashup
                let total_cash = this.toNumber(this.TOTAL_SOLD(this.CashOrdersItems))
                let opening_cash = this.toNumber(cashup?cashup.opening_cash:0)
                let current_cash = total_cash+opening_cash+TotalPayments

                this.input = {}
                this.input.till_key = cashup?cashup.till_key:null 
                this.input.expected_cash = current_cash
                // if (!edit_closing_cash) {
                //     this.input.closing_cash = current_cash 
                // }
 
                this.input.shortage = this.toNumber(this.input.expected_cash) - this.toNumber(this.input.closing_cash)
                
                //OPEN CONFIRM DIALOG
                // this.dialog_confirm_before_closing = true  
                let mbs_text = payload?payload.mbs_text:null 
                mbs_text = mbs_text?mbs_text:{...this.MBS.text.item_action("Register","Close")}
                let dialog = this.MBS.actions.dialogConfirmPassword 
                if (till_require_no_password) {
                    dialog = this.MBS.actions.dialog
                }else{
                    mbs_text.text=mbs_text.text+" Enter your password to confirm this action."
                    mbs_text.btnYes = "CONFIRM PASSWORD"
                } 

                // this.CLOSE_REGISTER()
                dialog({
                    show:true,
                    fixed:true,
                    icon:"$printer-pos-pause",
                    title:mbs_text.title, 
                    text:mbs_text.text, 
                    btnYes:mbs_text.btnYes, 
                    btnNoColor:"error", 
                    btnNo:"FORCE CLOSING", 
                    action:{
                        code:PAGE_NAME+"=CONFIRM-CLOSE-REGISTER",
                        silent_btn_no:true
                    }
                })
                
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'ON_CLOSE_REGISTER',
                    page:PAGE_NAME, 
                })
            }  
        }, 
        CONFIRM_BEFORE_CLOSING(password){
            try { 
                const all_orders = this.LiveLocalItemOrder
                const active_orders = this.ActiveOrders
                // IF ACTIVE ORDERS, CLOSE FIRST
                if (this.MBS.actions.SIZE(active_orders)) {
                    let mbs_text = this.MBS.text.item_action("Active Orders First","Close")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        icon:"mdi-cart",
                        title:"YOU HAVE OPEN ORDERS", 
                        text:"Hey there! It looks like you have some open orders. You'll need to close them out before you can close the register.\n\n Here are your options: \n 1. Close Orders: Go ahead and close each order individually. \n 2. Send to Unpaid Bills: If you want to put all these orders on a customer's account, click \"SEND UNPAID BILLS\".", 
                        btnNo:"CLOSE ORDERS", 
                        btnYes:"SEND UNPAID BILLS", 
                        btnNoColor:"error", 
                        action:{
                            code:PAGE_NAME+"=HAVE-OPEN-ORDERS",
                            silent_btn_no:true
                        }
                    })
                } else {
                    let settings = this.CompanySettings  
                    let cashup_before_closing_till = settings?settings.cashup_before_closing_till:null
                    let till_require_no_password = settings?settings.till_require_no_password:null
                    if (cashup_before_closing_till) {
                        this.dialog_confirm_before_closing = false
                        this.dialog_open_drawer_before_closing_register = true 
                        this.inputLoading = false  
                        setTimeout(() => { 
                            this.OPEN_DRAWER()
                        }, 100); 
                    } else {
                        this.CONFIRM_CLOSING_REGISTER()
                    } 
                } 

            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CONFIRM_BEFORE_CLOSING',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        CONFIRM_CLOSING_REGISTER(){
            try {  
                let mbs_text = this.MBS.text.item_action("Register","Close")
                this.MBS.actions.dialog({
                    show:true,
                    fixed:true,
                    title:mbs_text.title,
                    text:mbs_text.text,
                    btnYes:mbs_text.btnYes,  
                    btnNo:mbs_text.btnNo,  
                    action:{
                        code:PAGE_NAME+"=CLOSE-REGISTER"
                    }
                }) 
                 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CONFIRM_CLOSING_REGISTER',
                    page:PAGE_NAME, 
                })
            }  
        }, 
        CONFIRM_FORCE_CLOSING_REGISTER(){
            try {   
                 
                let mbs_text = this.MBS.text.item_action("Register","Force Close")
                this.MBS.actions.dialog({
                    show:true,
                    fixed:true,
                    title:mbs_text.title,
                    text:mbs_text.text,
                    btnYes:mbs_text.btnYes,  
                    btnNo:mbs_text.btnNo,  
                    action:{
                        code:PAGE_NAME+"=FORCE-CLOSE-REGISTER"
                    }
                }) 
                 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CONFIRM_FORCE_CLOSING_REGISTER',
                    page:PAGE_NAME, 
                })
            }  
        },  
        async FORCE_CLOSING_REGISTER(data){
            try {   
                await this.CLOSE_REGISTER(data) 
                // this.RESET_ALL_DATA()
                setTimeout(() => {
                    this.MBS.actions.logout(true) 
                }, 500); 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CONFIRM_FORCE_CLOSING_REGISTER',
                    page:PAGE_NAME, 
                })
            }  
        },  
        async CLOSE_REGISTER(){
            return new Promise(async (resolve,reject)=>{
                try {    
                    const settings = this.CompanySettings
                    const print_closing_cashup = settings?settings.print_closing_cashup:null

                    let us = this.us
                    let uid = us?us.uid:null

                    let OrdersItems = this.OrdersItems
                    let cashup_total_sales = this.TOTAL_SOLD(OrdersItems)


                    let saleOrders = this.CashupSaleOrders
                    let uploadedSaleOrders = this.UPLOADED(saleOrders)
                    let unUploadedSaleOrders = this.UN_UPLOADED(saleOrders) 
                    let cashup_pricing_tiers = this.OrdersPricingTiers 
                    let cash_items = this.CashOrdersItems
                    let on_account_items = this.OnAccountOrdersItems 
                    let OtherSalesOptionPayments = this.OtherSalesOptionPayments 
                    let OtherInvoiceOptionPayments = this.OtherInvoiceOptionPayments 

                    let InputPayments = this.InputPayments 
                    let InputCashPayments = this.toNumber(this.InputCashPayments )
                    let InputOtherPayments = this.toNumber(this.InputOtherPayments )

                    
                     
     
    
                    let input = this.input
                    let till_key = input?input.till_key:null
                    let expected_cash = input?input.expected_cash:null
                    // let closing_cash = input?input.closing_cash:null
                    let closing_cash = this.TotalCurrency
                    let currencies = this.currency_list
                    let description = input?input.description:''
                    let moment = this.MBS.date.moment
                    let key = moment?moment().valueOf():null
                    
                    let data = {
                        ...this.LocalCashup,

                        cashup_total_sales:cashup_total_sales,
                        cashup_pricing_tiers:cashup_pricing_tiers,

                        closing_currencies:currencies,
                        closing_cash:this.toNumber(closing_cash),
                        expected_cash:this.toNumber(expected_cash), 
    
                        sales_on_account:this.toNumber(this.TOTAL_SOLD(on_account_items)),  
    
                        sales_cash_payments:this.toNumber(this.TOTAL_SOLD(cash_items))+InputCashPayments,  
                        invoices_cash_payments:this.toNumber(this.TOTAL_AMOUNT(this.CashInvoicePayments)), 
     
                        close_at:key, 
                        closed:true, 
                        local_updated_at:key, 
                    }  
                    if (OtherSalesOptionPayments) {
                        data.sales_other_option_payments = {}
                        data.sales_total_other_option_payments = InputOtherPayments
                        OtherSalesOptionPayments.forEach(element => {
                            data.sales_other_option_payments[element.payment_option_key] = {
                                payment_option_key : element.payment_option_key,
                                amount : element.amount,
                                total_vat : element.total_vat,
                                sum_amount : element.sum_amount,
                            }
                            data.sales_total_other_option_payments = data.sales_total_other_option_payments+this.toNumber(element.amount)
                        });
                    }
    
                    if (OtherInvoiceOptionPayments) {
                        data.invoices_other_option_payments = {}
                        data.invoices_total_other_option_payments = 0
                        OtherInvoiceOptionPayments.forEach(element => {
                            data.invoices_other_option_payments[element.payment_option_key] = {
                                payment_option_key : element.payment_option_key,
                                amount : element.amount,
                            }
                            data.invoices_total_other_option_payments = data.invoices_total_other_option_payments + this.toNumber(element.amount)
                        });
                    }
                    if(description){data.description=description}
                    if(InputPayments){data.InputPayments=InputPayments} 
                    // return
                    this.inputLoading = true
                    let res =  await DB.addItems({
                        name:DATA.ITEMS.LOCAL_CASHUPS.values,
                        data:data,
                        update_locals:true,   
                    })   

                    // this.$store.dispatch("fi_uploadCashups")
                    // this.$store.dispatch("fi_upload_day_shifts")
                    this.$store.dispatch("fi_uploadSales")
                    // this.SUBMIT_CLOSE_CASHUP(data) 
                   
                   
                    this.$store.dispatch("upload_locals")
                    if (print_closing_cashup) {
                        let printer = this.CurrentReceiptPrinter
                        let printer_name = printer?printer.name:null
                        let printer_type_name = printer?printer.printer_type_name:null 
                        this.auto_print.printing = false
                        setTimeout(() => {  
                            this.REFRESH_DATA() 
                            this.auto_print.auto_printer_name = printer_name
                            this.auto_print.print_data = data 
                            this.auto_print.printing = true   
                            this.auto_print.auto = false   
                            this.auto_print.print_type = 'cashup'   
                            this.auto_print.printing_action={
                                code:PAGE_NAME+"=CLOSE-CASHUP",
                                data:data
                            } 
                        }, 0);  
                    }else{
                        setTimeout(() => {
                            this.REFRESH_DATA() 
                        }, 1000);
                    }
                    
                    resolve({success:true})
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CLOSE_REGISTER',
                        page:PAGE_NAME, 
                    }) 
                    reject(error)
                }  
            })
        },
        UPLOAD_SALES(data){
            return new Promise(async (resolve,reject)=>{
                try {    
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Uploading Sales...."
                    }) 
                    this.$store.dispatch("fi_uploadSales")
                    .then(async res=>{   
                        this.MBS.actions.progressDialog() 
                        this.SUBMIT_CLOSE_CASHUP(data)
                    }).catch(error=>{
                       console.log(error,'error.......');
                       this.MBS.actions.progressDialog() 
                       let mbs_text = this.MBS.text.item_action_fail("Sales","Uploading")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })
                    })  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'UPLOAD_SALES',
                        page:PAGE_NAME, 
                    }) 
                }  
            })
        },
        REFRESH_DATA(){
            // this.dialog_open_drawer_before_closing_register = false 
            // this.dialog=false, 
            // this.input={}, 
            // this.menuSelect=false,
            // this.dialog_drawer_close_register=false,   
            // this.currency_list=[
            //     {value:1,name:"1.00",icon:"mdi-cash"},
            //     {value:5,name:"5.00",icon:"mdi-cash"},
            //     {value:10,name:"10.00",icon:"mdi-cash"},
            //     {value:20,name:"20.00",icon:"mdi-cash"},
            //     {value:50,name:"50.00",icon:"mdi-cash"},
            //     {value:100,name:"100.00",icon:"mdi-cash"},
            //     {value:200,name:"200.00",icon:"mdi-cash"},
            //     {value:500,name:"500.00",icon:"mdi-cash"},
            //     {value:1000,name:"1,000.00",icon:"mdi-cash"},
            //     {value:2000,name:"2,000.00",icon:"mdi-cash"},
            //     {value:5000,name:"5,000.00",icon:"mdi-cash"},
            // ]

            Object.assign(this.$data, this.$options.data()) 
        },

        // async SUBMIT_CLOSE_CASHUP(data){
        //     try {   
        //         this.inputLoading = true
        //         if (data) { 
        //             this.inputLoading = false
        //             this.dialog_drawer_close_register = false 
        //             let saleOrders = this.AllLocalSaleOrdersData
        //             let cashups = this.AllLocalCashupsData
        //             let uploadedSaleOrders = this.UPLOADED(saleOrders) 
        //             let uploadedCashups = this.UPLOADED(cashups) 
        //             if (uploadedSaleOrders) {
        //                 for (const order of uploadedSaleOrders) {
        //                     let order_key = order.key
        //                     if (order_key) {
        //                         await DB.deleteItems({
        //                             key:order_key,
        //                             name:DATA.ITEMS.LOCAL_SALE_ORDERS.values,
        //                         }) 
        //                     }   
        //                 } 
        //             }
        //             if (uploadedCashups) {
        //                 for (const order of uploadedCashups) {
        //                     let order_key = order.key
        //                     if (order_key) {
        //                         DB.deleteItems({
        //                             key:order_key,
        //                             name:DATA.ITEMS.LOCAL_CASHUPS.values,
        //                         }) 
        //                     } 
        //                 }  
        //             }
        //             this.MBS.events.$emit("SUBMIT_SALES")
        //             this.MBS.events.$emit("SUBMIT_CASHUPS",PAGE_NAME)
        //         }else {
        //             this.inputLoading = false
        //             this.MBS.actions.error({
        //                 error:"no data",
        //                 from:'SUBMIT_CLOSE_CASHUP',
        //                 page:PAGE_NAME, 
        //             }) 
        //         } 
        //     } catch (error) {
        //         this.MBS.actions.error({
        //             error:error,
        //             from:'SUBMIT_CLOSE_CASHUP',
        //             page:PAGE_NAME, 
        //         }) 
        //     } 
        // },
        
        async CHECK_TILL(cashup){
            try {
                let selected_company = this.SELECTED_COMPANY
                let route = this.route
                let fullPath = route?route.fullPath:null
                let params = route?route.params:null
                let id = params?params.id:null  
                let company_key = cashup?cashup.company_key:null  
                if (!selected_company && company_key) {
                    this.MBS.actions.dialog()
                    this.SELECT_COMPANY({company_key:company_key})
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CHECK_TILL',
                    page:PAGE_NAME, 
                })
            }  
        }, 
        async SELECT_COMPANY(company){
            try {
                let route = this.route
                let fullPath = route?route.fullPath:null
                let params = route?route.params:null
                let id = params?params.id:null   
                await this.MBS.actions.SELECT_COMPANY(company)
                if (id) {
                    // let mainPath = fullPath.split(id).pop()
                    let newPath = fullPath.replace(id,company.company_key)
                    this.MBS.actions.go(newPath)
                }
                this.menuSelect = false

            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'SELECT_COMPANY',
                    page:PAGE_NAME, 
                })
            }  
        },  

        OPEN_DRAWER(){
            try {    
                //check connector 
                let K_CONNECTOR = this.K_CONNECTOR
                let settings = this.CompanySettings
                let Sell_without_printer = settings?settings.Sell_without_printer:null
                let printer = this.CurrentReceiptPrinter 
                if (K_CONNECTOR) { 
                    this.inputLoading = true
                    this.MBS.actions.EPSON_OPEN_DRAWER({printer:printer})
                    .then(res=>{
                        this.inputLoading = false
                        this.dialog_open_drawer = false
                    }).catch(error=>{
                        this.inputLoading = false
                        this.MBS.actions.dialog()
                        setTimeout(() => {
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:"ERROR",
                                text:'Unable to open drawer. ('+error.message+')',
                                btnYes:"TRY AGAIN",  
                                btnNo:"CANCEL",  
                                action:{
                                    code:PAGE_NAME+"=OPEN-DRAWER"
                                }
                            })   
                        }, 100);
                    })
                }else if (Sell_without_printer) {
                        
                } else {
                    this.inputLoading = false
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:"CONNECTOR IS OFF",
                        text:'Kipcount Connector is offline. Please make sure to start the K-Connector and try again.',
                        btnYes:"TRY AGAIN",  
                        btnNo:"CANCEL",  
                        action:{
                            code:PAGE_NAME+"=K-CONNECT"
                        }
                    })  
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'OPEN_DRAWER',
                    page:PAGE_NAME, 
                }) 
            } 
        },

        async COMPANY_QUICK_ACTIONS(actions){
            try {    
                if (actions) {
                    let current_till = this.CurrentTill
                    let till_key = current_till?current_till.key:null
                    actions.forEach(async action => {
                        if (till_key && till_key==action.till_key) {
                            if (action.action == "ask_to_close") {
                                this.MBS.actions.dialog({
                                    show:true,
                                    fixed:true,
                                    title:"CLOSE REGISTER",
                                    text:"Admin, is requesting to close the register.",
                                    btnYes:"CLOSE REGISTER",
                                    btnNo:"NOT NOW",
                                    action:{
                                        code:PAGE_NAME+"=ASK-TO-CLOSE-REGISTER",
                                        data:action
                                    }
                                })  
                                this.DELETE_COMPANY_QUICK_ACTION(action)
                            } 
                            if (action.action == "for_close_register") {
                                await this.FORCE_CLOSING_REGISTER(action) 
                                this.MBS.actions.dialog({
                                    show:true,
                                    fixed:true,
                                    title:"FORCE CLOSED REGISTER",
                                    text:"Admin,  closed the register.",
                                    btnYes:"OKAY", 
                                    // action:{
                                    //     code:PAGE_NAME+"=FORCE-CLOSE-REGISTER",
                                    //     data:action
                                    // }
                                })  
                                this.DELETE_COMPANY_QUICK_ACTION(action)
                            } 
                        }
                    });   
                } 
                    
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'ITEM_ACTION',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        DELETE_COMPANY_QUICK_ACTION(action){ 
            try { 
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.company_quick_actions+action.key,true) 
                this.$store.dispatch("fi_delete",{
                    action:DATA.ITEMS.COMPANY_QUICK_ACTIONS.values,
                    path:path, 
                }).then(res=>{ 
                }).catch(error=>{
                    console.log(error,'error.....'); 
                })
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'DELETE_COMPANY_QUICK_ACTION',
                    page:PAGE_NAME, 
                }) 
            }
        },

        //-------------[main]
        async DIALOG_YES(action){ 
            try {
                if (action.code) {
                    if (action.code ===PAGE_NAME+'=CONFIRM-SELECT-COMPANY') {
                        if (action.YES) {
                            setTimeout(() => { 
                                this.input.check = true 
                                this.MBS.actions.dialogInput({
                                    show:true,
                                    fixed:true,
                                    title:"CONFIRM",
                                    text:"Enter your password to confirm switching to "+this.MBS.actions.TEXT_UP(action.data.company_name)+".",
                                    btnYes:"CONFIRM",
                                    field:[
                                        {field:"password",label:'Password',type:'password'}
                                    ],
                                    action:{
                                        ...action,
                                        code:PAGE_NAME+"=SELECT-COMPANY",
                                    }
                                })  
                            }, this.MBS.data.ACTION_REFRESH_TIME); 
                        } else {
                            
                        }  
                    } 
                    if (action.code ===PAGE_NAME+'=SELECT-COMPANY') {
                        if (action.YES) {
                            setTimeout(() => { 
                                let password = action.password
                                password = password?this.MBS.crypt.encrypt(password):null 
                                if (!password) { 
                                    this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                }else if (password!==this.ud.p_uid) { 
                                    this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                } else {
                                    this.SELECT_COMPANY(action.data) 
                                }   
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        } else {
                            
                        }
                    }  
                    if (action.code ===PAGE_NAME+'=CONFIRM-CLOSE-REGISTER') {
                        if (action.YES) {
                            setTimeout(() => {   
                                this.CONFIRM_BEFORE_CLOSING()
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        } else { 
                            setTimeout(() => {   
                                this.CONFIRM_FORCE_CLOSING_REGISTER()
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        }     
                    } 
                    if (action.code ===PAGE_NAME+'=HAVE-OPEN-ORDERS') {
                        if (action.YES) {
                            setTimeout(() => {  
                                this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/sale",true))
                                setTimeout(() => {
                                    this.MBS.events.$emit("ON_VIEW_ORDERS","view_orders_list")   
                                }, 1000);
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        } else { 
                            setTimeout(() => {   
                                this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/sale",true))
                                setTimeout(() => {
                                    this.MBS.events.$emit("ON_VIEW_ORDERS","view_orders_list")   
                                }, 1000);
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        }     
                    } 
                    if (action.code ===PAGE_NAME+'=CLOSE-REGISTER') {
                        if (action.YES) {
                            this.CLOSE_REGISTER(action.data)
                        } else { 
                        }
                    }if (action.code ===PAGE_NAME+'=ASK-TO-CLOSE-REGISTER') {
                        if (action.YES) {
                            this.ON_CLOSE_REGISTER(action.data)
                            this.DELETE_COMPANY_QUICK_ACTION(action.data)
                        } else { 
                        }
                    }
                    if (action.code ===PAGE_NAME+'=FORCE-CLOSE-REGISTER') {
                        if (action.YES) {
                            await this.FORCE_CLOSING_REGISTER(action.data)  
                        } else { 
                        }
                    }
                    if (action.code ===PAGE_NAME+'=CLOSE-CASHUP') {
                        if (action.YES) {
                            this.UPLOAD_SALES(action.data)
                        } else {
                            // this.SUBMIT_CLOSE_CASHUP(action.data) 
                        }
                    }   
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'DIALOG_YES',
                    page:PAGE_NAME, 
                }) 
            }  
        },
    },
    beforeDestroy(){
        this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);    
        // this.MBS.events.$off('ON_CLOSE_REGISTER', this.ON_CLOSE_REGISTER); 
        // console.log('action closed--------------ii-- ');   
    },
    watch:{ 
        MyCompanies(value){  
        },
        Joined_LocalCashup(value){  
            this.CHECK_TILL(value)
        },
        route(value){ 
        }, 
        input(){
             
        },
        vsh(){
            if (this.menuSelect) {
                this.menuSelect = false
                setTimeout(() => {
                    this.menuSelect = true 
                }, 500);
            }  
        },
        menuSelect(value){ 
            setTimeout(() => {
                this.body_height = this.$refs.body_height?this.$refs.body_height.$el.clientHeight:0;   
            }, 100);
        },
        CompanyQuickActions(actions){
            this.COMPANY_QUICK_ACTIONS(actions)
        }, 
        
    }
}
</script> 